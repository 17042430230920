<template>
  <v-card tile id="market_watch_module" :width="!$store.state.isMobileView && !$store.state.isLapView ? '400' : !$store.state.isMobileView && $store.state.isLapView ? '450' : '100%'" class="mkWatch" right absolute @mouseleave="
      currentIndex = -1;isDropped = false;">
    <div class="d-flex align-center border-bottom-mwSearch">
      <customIcon  :name="'search'" id="mw_search_icon" class="mt-1 pl-2" :width="'16'" :height="'16'" :color="$store.state.amogaColor"  /> 
      <v-text-field id="mw_search_inp" height="40" class="fsize13" v-model="search" hide-no-data hide-details solo tile flat dense open-on-clear label="Search" @input="search ? search = search.toString().toUpperCase() : ''" @keyup.esc="onClickOutside"></v-text-field>
      <span id="mw_search_close_icon" v-if="search.length > 0" @click="search = ''" class="cursor"> <customIcon  :name="'close'" class="mt-2 pr-2" :width="'20'" :height="'20'" :color="$store.state.amogaColor"  />  </span>
      <span v-if="!loading" id="mw_length" class="secondaryColor fsize14 pr-4">{{ mwList.length }}/25</span>
    </div>
    <div class="height-2">
      <v-progress-linear :active="loading" :indeterminate="loading" rounded-lg height="2" color="blue"></v-progress-linear>
    </div>

    <!-- search list dropdown in mkWatch -->
    <v-sheet transition="fade-transition" tile class="searchContent" v-click-outside="onClickOutside" id="mw_search_list">
      <ul class="pl-0" id="mw_search_list_ul">
        <li class=" searchlist px-2 py-3 body-2  border-bottom-light d-flex align-center " v-for="(item, index) in searchList" :key="index" :id="`${index}_mw_search_list_li`">
           <span @click="handleMultiple(item, item.checked ? 'del' : 'add')" class="mr-5">
            <a style="line-height:8px" :id="`${index}_mw_search_list_check_box`">
              <customIcon :name="item.checked ? 'checked-outline' : 'check-outline'" :width="'24'" :height="'24'" :color="item.checked ? $store.state.amogaColor :'#757575'"/>
            </a></span>
          <a @click="item.checked ? onClickOutside() : handleMultiple(item,'add', true)" class="fsize14 searchScrip" :id="`${index}_mw_search_list_scrip_name`">{{
              item.formattedInsName == ""? item.instrument_name: item.formattedInsName }}</a>
          <v-spacer></v-spacer>
          <button class="fsize10 pa-0 rounded min-width-40" :id="`${index}_mw_search_list_scrip_ex`" :class="
              item.exch == 'NSE'
                ? 'buycolor'
                : item.exch == 'BSE'
                ? 'sellcolor'
                : item.exch == 'NFO'
                ? 'cancelled-msg'
                : 'cancelled-msg'
            " depressed min-width="36" max-width="36" height="26">
            {{ item.exch }}
          </button>
        </li>
      </ul>
    </v-sheet>

    <div class="scrollContents" v-if="!nodata">
      <v-expansion-panels transition="fade-transition" :readonly="true" v-model="depthExpand" flat tile accordion>
        <draggable class="w-100" @start="drag = true" :list="mwList" @end="drag = false;sortList;" :disabled="$store.state.isMobileView">
          <v-expansion-panel tile class="ma-0 hoverparent" v-for="(item, index) in mwList" :key="index" :id="`${index}_mw_list_scrip`">
            <v-expansion-panel-header hide-actions @mouseenter="; 
                isDropped = false;
                currentIndex = index;" @click="$store.state.isMobileView ? callBottomSheet(item) : '' ">
              <div class="d-flex justify-space-between align-center pb-2" :data-font-size="tableFontSize" >
                <span class="primaryColor " :id="`${index}_mw_list_scrip_name`">{{
                    item.scripName
                  }}  
               
                     </span><span :id="`${index}_mw_list_scrip_lp`" :class="
                      parseFloat(item.PerChange) >= 0
                        ? 'positiveColor'
                        : 'negativeColor'
                    ">{{ parseFloat(item.ltp).toFixed(item.Exchange == "CDS" || item.Exchange == "BCD" ? 4 : 2) }}</span>
              </div>
              <div class="d-flex justify-space-between align-center primaryColor" :sub-data-font-size="tableFontSize">
                <span class="secondaryColor bagIcon" :id="`${index}_mw_list_scrip_ex`">{{ item.Exchange }}   <customIcon
                  
                    v-if="item.hold && Number(item.hold) > 0"
                      :name="'holdBag'"
                      :width="'14'"
                      :height="'14'"
                      :fill="'#56585a'"
                    ></customIcon><span v-if="item.hold && Number(item.hold) > 0">{{ item.hold }}</span></span><span :class="
                      parseFloat(item.PerChange) >= 0
                        ? 'positiveColor'
                        : 'negativeColor'
                    " :id="`${index}_mw_list_scrip_chg`">{{ parseFloat(item.Change).toFixed(2) }}
                  <span :id="`${index}_mw_list_scrip_per_chg`" >({{ item.PerChange }}%)</span></span>
              </div>
              <div class="hoverbtns" v-if="currentIndex == index && !$store.state.isMobileView">
                <div class="d-flex align-center  absolute" :class="item.exSeg && item.exSeg =='INDEX' ? 'justify-center' : ''">
                  <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-btn @click="callOrderWindow('buy', item)" color="primary" min-width="30" max-width="34" height="24" depressed v-bind="attrs" v-on="on" class="text-capitalize fsize12">B</v-btn> -->
                      <label class="hoverBtn mr-2 buy" v-if="item.exSeg && item.exSeg !='INDEX'" :id="`${index}_mw_list_scrip_buy`" @click="callOrderWindow('buy', item)" v-bind="attrs" v-on="on">B</label>
                    </template>
                    <span>Buy</span>
                  </v-tooltip>

                  <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <label class="hoverBtn mr-2 sell" v-if="item.exSeg && item.exSeg !='INDEX'" :id="`${index}_mw_list_scrip_sell`" @click="callOrderWindow('sell', item)" v-bind="attrs" v-on="on">S</label>
                    </template>
                    <span>Sell</span>
                  </v-tooltip>

                  <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                       <label  class="hoverBtn mr-2" v-bind="attrs" :id="`${index}_mw_list_scrip_chart`" v-on="on" @click="callTrandingViewChart(item)">
                      <customIcon class="hoverImg d-flex align-center"  :name="'chart'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                       </label>
                    </template>
                    <span>Chart</span>
                  </v-tooltip>

                  <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                      <label  class="hoverBtn mr-2" v-bind="attrs" v-on="on" :id="`${index}_mw_list_scrip_market_depth`" @click="openPanel(index, item)">
                      <customIcon  class="hoverImg d-flex align-center"   :name="'depth'" :width="'20'" :height="'20'" :color="$store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Market Depth</span>
                  </v-tooltip>

                   <v-tooltip top color="toolTipColor toolTipTop">
                    <template v-slot:activator="{ on, attrs }">
                       <label  class="hoverBtn mr-2 " v-bind="attrs" :id="`${index}_mw_list_scrip_del`" v-on="on" @click="addorDeleteScrip(item, 'del')">
                      <customIcon  class="hoverImg d-flex align-center"   :name="'delete'" :width="'20'" :height="'20'" :color="$store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>

                  <div v-if="item.exSeg && item.exSeg !='INDEX'">
                    <v-tooltip top color="toolTipColor toolTipTop">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn   @mouseover="getWindowHeight(`${index}_mw_list_scrip_opt`)"  :id="`${index}_mw_list_scrip_opt`" v-if="currentIndex == index" @click="isDropped = !isDropped" min-width="30" max-width="35" height="25" outlined color="grey" depressed v-bind="attrs" v-on="on" class="hoverBtn d-flex align-center mr-2 text-capitalize fsize12">
                         <customIcon class="d-flex align-center"  :name="'more'" :width="'24'" :height="'24'" :color="$store.state.iconBlackColourCode" />
                        </v-btn>
                      </template>
                      <span>More</span>
                    </v-tooltip>

                    <transition name="slide">
                      <ul :class="isRotate ? 'bottom25' : ''" v-if="currentIndex == index && isDropped" class="pa-0 list mkWatchMoreList alignDropDown" :id="`${index}_mw_list_scrip_opt_ul`">
                        <li @click="
                              callalertDialog(item);
                              isDropped = false;
                            " class="primaryColor fsize12 cursor" :id="`${index}_mw_list_scrip_opt_alert_li`">
                          Alert
                        </li>
                        <li @click="
                              isDropped = false;
                              getInfo(item);
                            " class="primaryColor fsize12 cursor" :id="`${index}_mw_list_scrip_opt_info_li`">
                          Info
                        </li>
                        <!-- <li v-if="item.Exchange == 'NFO'" @click="
                              isDropped = false;
                              $store.dispatch('authentication/generateAuthCode',{url:'option/optionchain/0',symbol:item.symbolname});
                            " class="primaryColor fsize12 cursor">
                          Option Chain
                        </li> -->
                      </ul>
                    </transition>
                  </div>
                </div>
              </div>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- market depth -->
              <v-sheet class="mkdepth">
                <div class="depthLoaderCard" v-if="depthLoader">
                  <v-progress-circular indeterminate size="28" :width="2" color="blue"></v-progress-circular>
                </div>
                <div v-else>

                  <v-sheet class="mkdepth" id="mkdepth_sheet">
    <div flat class="d-flex align-center px-2 pt-2 pb-1">
      <div class="w-25 text-center">
        <p class="mb-1 fsize12  secondaryColor" id="mkdepth_open_label">Open</p>
        <p class="mb-1 fsize12 primaryColor" id="mkdepth_open_val">{{formatNum(item.o)}}</p>
      </div>
      <div class="w-25 text-center">
        <p class="mb-1 fsize12  secondaryColor" id="mkdepth_high_label">High</p>
        <p class="mb-1 fsize12 primaryColor" id="mkdepth_high_val">{{formatNum(item.h)}}</p>
      </div>
      <div class="w-25 text-center">
        <p class="mb-1 fsize12  secondaryColor" id="mkdepth_low_label">Low</p>
        <p class="mb-1 fsize12 primaryColor" id="mkdepth_low_val">{{formatNum(item.l)}}</p>
      </div>
      <div class="w-25 text-center">
        <p class="mb-1 fsize12  secondaryColor" id="mkdepth_close_label">Close</p>
        <p class="mb-1 fsize12 primaryColor" id="mkdepth_close_val">{{formatNum(item.c)}}</p>
      </div>
    </div>
    <div class="row ma-0 pa-2" v-if="item.exSeg && item.exSeg !='INDEX'">
      <table class="w-50 col-6 pa-0 buyTable wrap" id="mkdepth_buy_table">
        <thead id="mkdepth_buy_table_thead">
          <tr id="mkdepth_buy_table_thead_tr">
            <th class="font-weight-normal primaryColor pb-2 pr-4 fsize10 text-left" id="mkdepth_buy_bid_label">BID</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" id="mkdepth_buy_orders_label">ORDERS</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" id="mkdepth_buy_qty_label">QTY</th>
          </tr>
        </thead>
        <tbody id="mkdepth_buy_table_tbody">
          <tr class="pointer" @click=" callOrderWindow('buy', item, 'BPrice1')  " id="0_mkdepth_buy_table_tbody">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_buy_table_bp1">{{setTofixed(formatNum(item.BPrice1), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bo1">{{item.BOrders1}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bq1">{{item.BQty1}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('buy', item, 'BPrice2')  " id="1_mkdepth_buy_table_tbody">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_buy_table_bp2">{{setTofixed(formatNum(item.BPrice2), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bo2">{{item.BOrders2}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bq2">{{item.BQty2}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('buy', item, 'BPrice3')  " id="2_mkdepth_buy_table_tbody">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_buy_table_bp3">{{setTofixed(formatNum(item.BPrice3), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bo3">{{item.BOrders3}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bq3">{{item.BQty3}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('buy', item, 'BPrice4')  " id="3_mkdepth_buy_table_tbody">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_buy_table_bp4">{{setTofixed(formatNum(item.BPrice4), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bo4">{{item.BOrders4}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bq4">{{item.BQty4}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('buy', item, 'BPrice5')  " id="4_mkdepth_buy_table_tbody">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_buy_table_bp5">{{setTofixed(formatNum(item.BPrice5), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bo5">{{item.BOrders5}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_buy_table_bq5">{{item.BQty5}}</td>
          </tr>
        </tbody>
        <tfoot id="mkdepth_buy_table_tfoot">
          <tr id="mkdepth_buy_table_tfoot_tr">
            <td class="text-left fsize12 py-1 pr-4" id="mkdepth_buy_table_tfoot_tot">Total</td>
            <td colspan="2" class="text-right fsize12 py-1 pr-2" id="mkdepth_buy_table_tfoot_tot_val">{{ruppesFormat(formatNum(item.totalbuyqty))}}</td>
          </tr>
        </tfoot>
      </table>
      <table class="w-50 col-6 pa-0 sellTable wrap" id="mkdepth_sell_table">
        <thead id="mkdepth_sell_table_thead">
          <tr id="mkdepth_sell_table_thead_tr">
            <th class="font-weight-normal primaryColor pb-2 pr-4 fsize10 text-left" id="mkdepth_sell_bid_label">OFFER</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" id="mkdepth_buy_orders_label">ORDERS</th>
            <th class="font-weight-normal primaryColor pb-2 pr-2 fsize10 text-right" id="mkdepth_buy_qty_label">QTY</th>
          </tr>
        </thead>
        <tbody id="mkdepth_sell_table_tbody">
          <tr  class="pointer" @click=" callOrderWindow('sell', item, 'SPrice1') " id="0_mkdepth_sell_table_tbody_tr">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_sell_table_sp1">{{setTofixed(formatNum(item.SPrice1), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_so1">{{item.SOrders1}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_sq1">{{item.SQty1}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('sell', item, 'SPrice2') " id="1_mkdepth_sell_table_tbody_tr">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_sell_table_sp2">{{setTofixed(formatNum(item.SPrice2), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_so2">{{item.SOrders2}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_sq2">{{item.SQty2}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('sell', item, 'SPrice3') " id="2_mkdepth_sell_table_tbody_tr">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_sell_table_sp3">{{setTofixed(formatNum(item.SPrice3), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_so3">{{item.SOrders3}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_sq3">{{item.SQty3}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('sell', item, 'SPrice4') " id="3_mkdepth_sell_table_tbody_tr">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_sell_table_sp4">{{setTofixed(formatNum(item.SPrice4), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_so4">{{item.SOrders4}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_sq4">{{item.SQty4}}</td>
          </tr>
          <tr class="pointer" @click=" callOrderWindow('sell', item, 'SPrice5') " id="4_mkdepth_sell_table_tbody_tr">
            <td class="text-left fsize12 pb-1 pr-4" id="mkdepth_sell_table_sp5">{{setTofixed(formatNum(item.SPrice5), item.Exchange)}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_so5">{{item.SOrders5}}</td>
            <td class="text-right fsize12 pb-1 pr-2" id="mkdepth_sell_table_sq5">{{item.SQty5}}</td>
          </tr>
        </tbody>
        <tfoot id="mkdepth_sell_table_tfoot">
          <tr id="mkdepth_sell_table_tfoot_tr">
            <td class="text-left fsize12 py-1 pr-4" id="mkdepth_sell_table_tot">Total</td>
            <td colspan="2" class="text-right fsize12 py-1 pr-2" id="mkdepth_sell_table_tot_val">{{ruppesFormat(formatNum(item.totalsellqty))}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="d-flex w-100 pa-2 fsize12" v-if="item.exSeg && item.exSeg !='INDEX'">
      <div class="w-50 pr-2">
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_vol_label">Volume</div>
          <div class="primaryColor pb-1" id="mkdepth_vol_val">{{ruppesFormat(item.volume)}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_ltq_label">LTQ</div>
          <div class="primaryColor pb-1" id="mkdepth_ltq_val">{{item.lasttradedqty}}</div>
        </div>
        <div class="d-flex justify-space-between"  v-if="item.Exchange != 'NSE' && item.Exchange != 'BSE'">
          <div class="secondaryColor pb-1" id="mkdepth_expiry_label">Expiry</div>
          <div class="primaryColor pb-1" id="mkdepth_expiry_val">{{item['expDt'] && item['expDt'] != "NA" ? item['expDt'] : 'NA'}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_lc_label">Lower Circuit</div>
          <div class="primaryColor pb-1" id="mkdepth_lc_val">{{formatNum(item.lc)}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_lut_label">LUT</div>
          <div class="primaryColor pb-1" id="mkdepth_lut_val">{{item.lasttradedtime}}</div>
        </div>
        <div class="d-flex justify-space-between" v-if="item.Exchange != 'NFO'">
          <div class="secondaryColor pb-1" id="mkdepth_52_week_high_label">52 Week High</div>
          <div class="primaryColor pb-1" id="mkdepth_52_week_high_val">{{item.yearlyhighprice == '0' ? 'NA' : formatNum(item.yearlyhighprice)}}</div>
        </div>
      </div>
      <div class="w-50">
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_avg_prc_label">Avg.Price</div>
          <div class="primaryColor pb-1" id="mkdepth_avg_prc_val">{{formatNum(item.weightedavg)}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_ltt_label">LTT</div>
          <div class="primaryColor pb-1" id="mkdepth_ltt_val">{{item.lasttradedtime}}</div>
        </div>
        <div class="d-flex justify-space-between" v-if="item.Exchange != 'NSE' && item.Exchange != 'BSE'">
          <div class="secondaryColor pb-1" id="mkdepth_oi_label">OI</div>
          <div class="primaryColor pb-1" id="mkdepth_oi_val">{{item.openinterest}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_uc_label">Upper Circuit</div>
          <div class="primaryColor pb-1" id="mkdepth_uc_val">{{formatNum(item.uc)}}</div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="secondaryColor pb-1" id="mkdepth_ttv_label">TTV</div>
          <div class="primaryColor pb-1" id="mkdepth_ttv_val">{{convertLacs(item.volume,item.weightedavg)}}</div>
        </div>
        <div class="d-flex justify-space-between" v-if="item.Exchange != 'NFO'">
          <div class="secondaryColor pb-1" id="mkdepth_52_week_low_label">52 Week Low</div>
          <div class="primaryColor pb-1" id="mkdepth_52_week_low_val">{{item.yearlylowprice == '0' ? 'NA' : formatNum(item.yearlylowprice)}}</div>
        </div>
      </div>
    </div>
  </v-sheet>
                </div>
              </v-sheet>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>
    </div>

    <!-- No data Part -->
    <div class="scrollContents d-flex flex-column align-center justify-center" v-if="nodata && !loading" id="mw_no_data_val">
      <img width="50%" src="../assets/images/noData.svg" alt="noDataImg" class="mb-2" id="mw_no_data_img"/>
      NO DATA FOUND
    </div>

    <!-- Footer Tabs -->
    <div class="border-top">
      <div class="footerTab border-top-midDark w-100" id="mw_footer_tab">
        <v-bottom-navigation class="justify-start" height="40" min-width="auto" id="mw_footer_bottom_navigation" max-width="auto" :value="value" absolute mandatory tile color="primary white">
          <v-btn class="font-weight-bold border-right-dark" @change="changeTab(index)" active-class="activeMkBtn" :id="`${index}_mw_footer_bottom_btn`" max-width="60" min-width="48" height="40" v-for="(item, index) in 5" :key="index">
            {{ item }}
          </v-btn>
        </v-bottom-navigation>
        <v-menu transition="scroll-y-reverse-transition" left top :offset-y="true" min-width="100" :close-on-content-click="false">
         <template v-slot:activator="{ on, attrs }">
            <v-btn :ripple="false" v-bind="attrs" v-on="on" id="mw_footer_setting_icon" tile depressed active-class="activeMkBtn" class="float-right settingsIcon" max-width="60" min-width="48" height="40">
              <customIcon :name="'settings'" :width="'18'" :height="'18'" :color="$store.state.iconBlackColourCode" />
            </v-btn>
          </template>
          <v-card class="pa-2 d-flex flex-column" height="150">
            <label class="fsize12 primaryColor mb-1" id="mw_footer_font_resize_label">Font Resize</label>
            <v-btn-toggle color="blue accent-4" class="mb-2 font-weight-bold" id="mw_footer_font_resize_btn_toggle" v-model="toggleBtns" mandatory dense>
              <v-btn class="fsize12" v-for="(item, index) in settingsList" :id="`${index}_mw_footer_font_resize_btn`" :key="index" @click="fontResizer(item)">{{ item }}</v-btn>
            </v-btn-toggle>
            <span class="fsize12 primaryColor mb-1" id="mw_footer_sort_by_label">Sort by</span>
            <v-btn-toggle color="blue accent-4" class="font-weight-bold" id="mw_footer_sort_by_btn_toggle" v-model="filterToggle" dense>
              <v-btn class="fsize12" v-for="(item, index) in filterList" :id="`${index}_mw_footer_sort_by_btn`" :key="index" @click="sortedArray(item.toLowerCase())">{{ item }}</v-btn>
            </v-btn-toggle>
          </v-card>
        </v-menu>
      </div>
    </div>

    <!-- Alert dialog -->

    <v-dialog v-model="alertDialog" width="500px">
      <v-expand-transition>
        <v-card class="pa-0">
          <div class="border-bottom-dark fsize16 font-weight-bold px-4 pt-4 pb-2" id="secrity_trade_alert_label">
            Security Trade Alert
          </div>
          <div class="px-3 pb-4">
            <div class="py-3">
              <div class="pb-2 d-flex justify-space-between">
                <div>
                  <span class="fsize13 primaryColor" id="secrity_trade_alert_scrip_name">
                    {{
                      alertData.formatScripName == null
                        ? alertData.TradSym
                        : alertData.formatScripName
                    }}
                  </span>
                  <span class="fsize9 secondColor ml-3" id="secrity_trade_alert_scrip_ex">
                    {{ alertData.Exchange }}
                  </span>
                </div>
                <div class="fsize13 primaryColor" id="secrity_trade_alert_scrip_ltp">{{ alertData.ltp }}</div>
              </div>

              <div>
                <div @keypress.enter="alertSubscribe()">
                  <v-text-field type="number" v-model.number="alertPrice" id="secrity_trade_alert_scrip_inp" label="Alert me when price reaches..." @keypress="keyPressAlphaNumeric($event)" min="0" outlined hide-details :required="false" dense class="mt-2 fsize13"></v-text-field>
                  <div class="red--text fsize12 mt-2 ml-2 h-16">
                    <span v-if="alertPrice == '' && submitted" id="secrity_trade_alert_scrip_err_msg">Please Enter Price</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <v-btn height="32" min-width="80" id="secrity_trade_alert_scrip_confirm_btn" depressed :loading="alertLoader" :disabled="alertLoader" :color="$store.state.buttonThemeColor" class="fsize14 text-capitalize white--text" @click="alertSubscribe()">Submit</v-btn>
              <v-btn height="32" min-width="80" id="secrity_trade_alert_scrip_cancel_btn" depressed class="ml-2 fsize14 text-capitalize" outlined @click="$store.commit('marketWatch/setAlertDialog', false)">
                Cancel
              </v-btn>

            </div>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- info dialog -->

    <v-dialog v-model="infoDialog" width="650px" :transition="$store.state.appName == 'Zebull' ? 'slide-x-reverse-transition' : 'slide-x-transition'">
      <v-card class="pa-5">
        <div class="d-flex align-baseline py-3">
          <div class="mx-2 fsize14" id="info_dialog_scrip_name">
            {{ this.currentInfoData.TradSym }}
          </div>
          <div class="fsize9" id="info_dialog_scrip_ex">{{ this.currentInfoData["Exchange"] }}</div>
          <div class="mt-1 fsize14 primaryColor orderalign" id="info_dialog_scrip_price_range">
            Price Range : {{ this.securityInfoData.LCircuitLimit }} -
            {{ this.securityInfoData.HCircuitLimit }}
          </div>
        </div>
        <v-divider class="mx-2 mb-2"></v-divider>
        <div class="">
          <div class="py-2">
            <div class="row ma-0">
              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_expiry_label">Expiry</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_expiry_val">
                    {{
                      securityInfoData.ExpiryDte != null
                        ? securityInfoData.ExpiryDte.split("-")[0]
                        : securityInfoData.ExpiryDte
                    }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_spot_price_label">Spot Price</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_spot_price_val">
                    {{ this.currentInfoData["spotprice"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_tick_size_label">Tick Size</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_tick_size_val">
                    {{ this.securityInfoData["TickSize"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_min_lot_label">Min Lot</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_min_lot_val">
                    {{ this.securityInfoData["Blq"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_ltd_label">
                    Last Trading Date
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_ltd_val">
                    {{ this.securityInfoData["LastTradingDate"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between" id="info_dialog_scrip_start_date_label">
                  <div class="fsize12 secondaryColor mb-2">Start Date</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_start_date_val">
                    {{ this.securityInfoData["IsuStartDate"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_oi_label">Open Interest</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_oi_val">
                    {{ this.securityInfoData["openinterest"] }}
                  </div>
                </div>
              </div>
              <v-divider vertical></v-divider>

              <div class="col-6 py-4 px-4">
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_price_quatation_label">Price Quatation</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_price_quatation_val">
                    {{ this.securityInfoData["PriceQuatation"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_max_quan_label">
                    Max Quantity (in Lots)
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_max_quan_val">
                    {{ this.securityInfoData["MaxOrderSize"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_time_label">Time</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_time_val">
                    {{ this.securityInfoData["LTT"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_ex_time_label">Exchange time</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_ex_time_val">
                    {{ this.securityInfoData["LTT"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_end_date_label">End Date</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_end_date_val">
                    {{ this.securityInfoData["PriceQuatation"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between" >
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_tot_trade_vol_label">
                    Total Trade Volume
                  </div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_tot_trade_vol_val">
                    {{ this.currentInfoData["TradeVolume"] }}
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2" id="info_dialog_scrip_tot_market_type_label">Market Type</div>
                  <div class="fsize12 primaryColor mb-2" id="info_dialog_scrip_tot_market_type_val">
                    {{ this.securityInfoData["MarketType"] }}
                  </div>
                </div>
              </div>
              <div class="row pt-4 ma-0 d-flex justify-end">
                <v-btn depressed outlined class="  fsize14 text-capitalize" id="mw_info_close_btn" width="60px" height="32px" @click="infoDialog = false">Close</v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

   

    <bottomsheet :currentData="currentSheetData" v-on:from-child ="getChildData"/>

  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import errorHandling from "../store/Services/errorHandling.js";
import orderWindowjs from "../mixins/orderWindow";
import bottomsheet from '../components/bottomSheet.vue'
import customIcon from '../components/customIcon.vue'
import commonFuns from '../mixins/commonFunctions'

export default {
  mixins: [orderWindowjs, commonFuns],
  data: () => ({
    search: "",
    depthExpand: null,
    settingsList: ["S", "M", "L", "XL"],
    filterList: ["A-Z", "(%)", "LTP", "Exc"],
    tableFontSize: "M",
    toggleBtns: 1,
    filterToggle: "",
    infoDialog: false,
    currentInfoData: "",
    alertData: "",
    alertPrice: "",
    submitted: false,
    isDropped: false,
    currentIndex: -1,
    isRotate: false,
    currentSheetData: [],
    searchTimer: 0,
    addTimer: 0
  }),

  components: {  draggable, bottomsheet,customIcon },

  computed: {
    ...mapState("marketWatch", [
      "mwList",
      "nodata",
      "searchList",
      "depthLoader",
      "securityInfoData",
      "loading",
      "alertLoader",
      "watchList"
    ]),
    ...mapActions("marketWatch", ["sortList"]),
    ...mapGetters("marketWatch", ["getAlertDialog"]),
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
    ]),

    alertDialog: {
      get() {
        return this.getAlertDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("marketWatch/setAlertDialog", value);
        }
        return value;
      },
    },
    value: {
      get(){
        return this.$store.state.marketWatch.tab
      },
      set(value){
        this.$store.commit("marketWatch/setTab", value);
      }
    }
  },

  methods: {
    getChildData(data){
      if(data['page'] == 'MkWatch'){
        if(data['action'] == 'buy' || data['action'] == 'sell'){
          this.callOrderWindow(data['action'], data.item)
        }
         if(data['action'] == "chart"){
          this.callTrandingViewChart(data.item)
        }
         if(data['action'] == 'depth'){
          const index = this.mwList.indexOf(data.item)
          this.openPanel(index, data.item)
        }
         if(data['action'] == 'del'){
          this.addorDeleteScrip(data.item, 'del')
        }
         if(data['action'] == 'Alert'){
          this.callalertDialog(data.item)
        }
         if(data['action'] == 'info'){
          this.getInfo(data.item)
        }
      }
    },
       callBottomSheet(val){
         this.openPanel(null, "item")
         this.currentSheetData = []
      var tempData = {
        where: 'MkWatch',
        isOpen: true,
        item: val,
      }
      this.currentSheetData.push(tempData)
    },
    callTrandingViewChart(item) {
      let temp ={
                    data: item,
                    where:  'mw'
                }
      this.$store.commit("setChartData", temp);
      if (this.$router.currentRoute.path == "/chart") {
        this.$root.$refs.TVChartContainer.refresh(temp);
      } else {
        this.$router.push("/chart").catch(() => {});
        localStorage.setItem("chartData", JSON.stringify(temp));
      }
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 90;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    onClickOutside() {
      this.search = "";
      this.$store.commit("marketWatch/setSearchList", []);
      clearTimeout(this.searchTimer)
      clearTimeout(this.addTimer)
    },
    handleMultiple(data, type, isSingle){
        clearTimeout(this.addTimer)
        this.addTimer = setTimeout(()=>{
          this.addorDeleteScrip(data, type, isSingle)
        }, 500)
    },
    async addorDeleteScrip(data, type, isSingle) {
      this.openPanel(null, "item");
      var exchangeInput = data.exch;
      var isValidExchange = true;
      // this.getUserSessionDto["exch"].forEach((element) => {
      //   if (element == exchangeInput) {
      //     isValidExchange = true;
      //   }
      // });
      let addOrDel = {
        userId: this.getUserId,
        mwId: this.watchList[this.value].mwId,
        scripData :[
        {
            exch :  data.exch ? data.exch : data.Exchange,
            token : data.token,
        }
        ]
      };
      if (type == "del") {
        this.$store.dispatch("marketWatch/deleteScripFromList", {
          addDel: addOrDel,
          data: data,
        });
      } else if (isValidExchange) {
        if (type == "add") {
          this.$store.dispatch("marketWatch/addScripList", {
            addDel: addOrDel,
            data: data,
          });
        }
      } else {
        errorHandling.toaster('', "danger", "Dear Customer, Kindly activate your " + addOrDel.exch + " segment before adding" , 3000)
      }
      if (isSingle) {
        this.onClickOutside();
      }
    },

    /***
     * @author Senthil
     * Market depth open Close function
     ***/
    async openPanel(index, item) {
      this.depthExpand == index
        ? (this.depthExpand = null)
        : (this.depthExpand = index);
      this.depthExpand != null
        ? this.$store.dispatch("wsConnection/depthSubscription",item.Exchange + "|" + item.token)
        : this.$store.dispatch("wsConnection/depthSubscription", "");
    },

    async callDepth(item) {
      this.$store.commit("marketWatch/setDepthLoader", true);
      this.$store.commit("marketWatch/setPriceRange", []);
      this.$store.dispatch("marketWatch/getNewPriceRange", item);
    },

    /***
     * @author Senthil
     * font Resize Method
     ***/
    fontResizer(value) {
      this.tableFontSize = value;
    },

    /***
     * @author Senthil
     * Date 04-12-2021
     * sorting market watch method
     ***/
    async sortedArray(sortType) {
      this.activeFilterItem =
        this.activeFilterItem == ""
          ? sortType
          : sortType == this.activeFilterItem
          ? sortType == "a-z"
            ? "z-a"
            : sortType == "(%)"
            ? "despercentage (%)"
            : sortType == "ltp"
            ? "desltp"
            : sortType == "exc"
            ? "desexchange"
            : "a-z"
          : sortType;
      this.activeFilterItem == "a-z"
        ? this.mwList.sort((a, b) =>
            a.TradSym > b.TradSym ? 1 : b.TradSym > a.TradSym ? -1 : 0
          )
        : this.activeFilterItem == "(%)"
        ? this.mwList.sort((a, b) =>
            parseFloat(a.PerChange) > parseFloat(b.PerChange)
              ? 1
              : parseFloat(b.PerChange) > parseFloat(a.PerChange)
              ? -1
              : 0
          )
        : this.activeFilterItem == "ltp"
        ? this.mwList.sort((a, b) =>
            parseFloat(a.ltp) > parseFloat(b.ltp)
              ? 1
              : parseFloat(b.ltp) > parseFloat(a.ltp)
              ? -1
              : 0
          )
        : this.activeFilterItem == "z-a"
        ? this.mwList.sort((a, b) =>
            b.TradSym > a.TradSym ? 1 : a.TradSym > b.TradSym ? -1 : 0
          )
        : this.activeFilterItem == "despercentage (%)"
        ? this.mwList.sort((a, b) =>
            parseFloat(b.PerChange) > parseFloat(a.PerChange)
              ? 1
              : parseFloat(a.PerChange) > parseFloat(b.PerChange)
              ? -1
              : 0
          )
        : this.activeFilterItem == "desltp"
        ? this.mwList.sort((a, b) =>
            parseFloat(b.ltp) > parseFloat(a.ltp)
              ? 1
              : parseFloat(a.ltp) > parseFloat(b.ltp)
              ? -1
              : 0
          )
        : this.activeFilterItem == "desexchange"
        ? this.mwList.sort((a, b) =>
            b.Exchange > a.Exchange ? 1 : a.Exchange > b.Exchange ? -1 : 0
          )
        : this.mwList.sort((a, b) =>
            a.Exchange > b.Exchange ? 1 : b.Exchange > a.Exchange ? -1 : 0
          );
      this.openPanel(null, "item");
    },

    isScripAlreadyHere(item) {
      return this.mwList == undefined ||
        this.mwList == null ||
        this.mwList.length == 0
        ? true
        : this.mwList.some((element) => element.token != item.token);
    },

    /***
     * @author Senthil
     * footer tabs
     ***/
    async changeTab(value) {
      value = value ? value : 0;
      this.value = value
      this.onClickOutside();
      this.depthExpand = null;
      this.$store.state.wsConnection.preDepthSubsription != ""
        ? this.$store.dispatch("wsConnection/depthSubscription", "")
        : "";
       this.$store.commit("marketWatch/setCurrentList")
    },

    async callOrderWindow(orderType, value, from) {
      this.$store.commit("orderWindow/setOrderWindow", true);
      if(from) {
        this.$store.commit('orderWindow/setDepthOrder', from)
      }
      this.$store.dispatch("orderWindow/setInitialValues", {
        data: value,
        page: "mkWatch",
      });
      this.$store.commit("orderWindow/setWindowLoading", true);
      orderType == "buy" ? false : true;
      this.$store.commit("orderWindow/setCurrentOrder", {
        data: value,
        page: "mkWatch",
      });
      
      this.$store.commit("orderWindow/setRemoveCss", true);
      this.$store.commit("orderWindow/setOrderType", orderType);
      
      this.changeOrderTab();
      
    },

    getInfo(currentData) {
      this.$store.dispatch("marketWatch/getSecurityInfo", currentData);
      this.infoDialog = true;
      this.currentInfoData = currentData;
    },
    callalertDialog(val) {
      this.alertPrice = "";
      this.alertData = val;
      this.submitted = false;
      this.$store.commit("marketWatch/setAlertDialog", true);
    },

    async alertSubscribe() {
      this.submitted = true;
      if (this.alertPrice != "" && this.alertPrice != "") {
        await this.$store.dispatch("marketWatch/alertDialog", {
          data: this.alertData,
          alertPrice: this.alertPrice,
        });
      }
    },

    validate(evt) {
      return /^[0-9]*\.?[0-9]*$/.test(this.alertPrice + evt.key);
    },
    keyPressAlphaNumeric(event) {
      if (event.keyCode != 13) {
        var inp = String.fromCharCode(event.keyCode);
         if ((/[0-9.]/.test(inp) && !this.alertPrice.toString().includes('.')) ||  (/[0-9.]/.test(inp) && this.alertPrice.toString().includes('.') && this.alertPrice.toString().split('.')[1].length < 4)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
     checkWatchList(data){
      }
  },
 async mounted() {
    this.value = localStorage.getItem("tabIndex") && localStorage.getItem("tabIndex") != "undefined" ? JSON.parse(localStorage.getItem("tabIndex")) : 0;
    this.changeTab(this.value);
    await this.$store.dispatch('holdings/getHoldings')
      await this.$store.dispatch("marketWatch/fetchMwLists");
      
  },
  watch: {
    // search scrips in marketWatch
    search:  function (value) {
      clearTimeout(this.searchTimer)
      if(value && value.length > 1){
        this.searchTimer = setTimeout(()=>{
      this.$store.dispatch(
            "marketWatch/getSearchScripts",
            value.toString().toUpperCase()
          )
        }, 500)
        
      }
    },
  },
  created(){
    window.checkWatchList = this.checkWatchList
  }
};
</script>

<style scoped>

.mt-1p{
  margin-top: 2px;
}
.mkWatch {
  position: fixed;
  right: 0;
  top: 56px;
  height: calc(100vh - 56px);
  resize: horizontal;
  border-left: 1px solid#a2a2a2 !important;
}
.bagIcon {
  display: flex;
  gap: 6px;
 
}
.scrollContents {
  height: calc(100vh - 138px);
  overflow-y: auto;
}

.activeMkBtn {
  background-color: #dee9fe !important;
  color: #0028ff !important;
  font-weight: bold !important;
}

.settingsBtnGroup {
  color: #170055 !important;
  background-color: #dfd3ff !important;
}

.theme--dark .activeMkBtn {
  background-color: #213b52 !important;
  color: #2992ec !important;
}

::v-deep .v-input__slot {
  box-shadow: none !important;
}

input[type="text"] {
  text-indent: 20px !important;
}

.searchlist {
  list-style: none !important;
}

.searchContent {
  z-index: 2;
  max-height: calc(100vh - 145px) !important;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background: white;
}

::v-deep .v-expansion-panel-header {
  display: block !important;
  padding: 8px 16px !important;
  transition: none !important;
  line-height: unset !important;
}

::v-deep .v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}

::v-deep .v-expansion-panel {
  transition: none !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.theme--dark .v-expansion-panel {
  border-bottom: 1px solid #393939 !important;
}

.theme--dark .searchContent ul {
  background-color: #1e1e1e;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none !important;
}
/* ::v-deep .v-icon {
  font-size: 18px !important;
} */
::v-deep .v-item-group.v-bottom-navigation {
  width: 87% !important;
  box-shadow: none !important;
}
::v-deep .theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
}
/* tabs overwrite */
.mkWatch .theme--light.v-tabs .v-tab--active {
  opacity: 0.12 !important;
}
.footerTab {
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.settingsIcon {
  width: calc(100% - 50px);
}
.hoverImg {
  max-width: 60%;
}
.depthLoaderCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 377px;
}

.theme--dark .stsImglight {
  display: none;
}
.theme--light .stsImgdark {
  display: none;
}

/* font-resize class binding */
[data-font-size="S"] {
  font-size: 12px !important;
}
[data-font-size="M"] {
  font-size: 14px !important;
}
[data-font-size="L"] {
  font-size: 16px !important;
}
[data-font-size="XL"] {
  font-size: 18px !important;
}
[sub-data-font-size="S"] {
  font-size: 10px !important;
}
[sub-data-font-size="M"] {
  font-size: 11px !important;
}
[sub-data-font-size="L"] {
  font-size: 12px !important;
}
[sub-data-font-size="XL"] {
  font-size: 13px !important;
}
.searchScrip {
  color: #282828;
}
.height-2{height:2px !important;}
.v-expansion-panel-header:before{
  background-color: white !important;
}
</style>