import { mapActions, mapState, mapGetters } from "vuex";
import errorHandling from "../store/Services/errorHandling";
export default {
  data() {
    return {
      nseKey: "123.45",
      text: "center",
      priceItems: ["L", "MKT", "SL", "SL-M"],
      productItems: ["MIS", "NRML"],
      // showValidityItems: ['DAY', 'IOC'],
      // validityType: "",
      showMore: false,
      coverOrBracketValidityItems: ["DAY"],
      coverOrBracketProductItems: ["MIS"],
      targetPrice: 0,
      stopLossPrice: 0,
      trailStopLossPrice: 0,
      // disclosedQuantity: 0,

      //input field hide show
      currentOrderTab: "regular",
      isFormSubmit: false,
      requestData: "",
    };
  },

  components: {
    draggable: () =>
      import(/* webpackChunkName: "draggable" */ "../components/draggable.vue"),
  },

  computed: {
    ...mapState("marketWatch", ["marketDepthData"]),
    ...mapState("orderWindow", [
      "showOrderWindow",
      "orderType",
      "toggleOrder",
      "priceRangeDetails",
      "nsePriceRangeData",
      "bsePriceRangeData",
      "scriptQuoteDetails",
      "currentOrder",
      "minlot",
      "ltp",
      "tickPrice",
      "currentOrder",
      "nseBseType",
      "currentInstrument",
      "currentExchange",
      "currentPage",
      "modifyOrder",
      "repeatOrder",
      "loading",
      "windowLoading",
      "isNfo",
      "orderTypeList",
      "validityItems",
      "bracketPriceItems",
      "coverPriceItems",
      "lowerRange",
      "upperRange",
      "removeCss",
      "isPrice",
      "isTrgPrice",
      "isDisclosedQty",
      "nseSwitchPrice",
      "bseSwitchPrice",
      "selectedItem",
      "brokerageLoader",
      "isBrokerageCalled",
      "brokerageData",
      "refreshBrokerageLoader",
      "marginLoader",
      "isNudge",
      "nudgeMessage",
      "freezeQty"
    ]),
    ...mapGetters("orderWindow", [
      "getPriceType",
      "getProdType",
      "getOrderTab",
      "getValidityType",
      "getTriggerPrice",
      "getCurrentLtp",
      "getAvailableMargin",
    ]),

    showproductItems: {
      get() {
        return this.$store.state.orderWindow.showproductItems;
      },
      set(newValue) {
        this.$store.commit("orderWindow/setProductItems", newValue);
      },
    },

    showPriceItems: {
      get() {
        return this.$store.state.orderWindow.showPriceItems;
      },
      set(newValue) {
        this.$store.commit("orderWindow/setPriceItems", newValue);
      },
    },

    quantity: {
      get() {
        return this.$store.state.orderWindow.quantity;
      },
      set(value) {
        this.$store.commit("orderWindow/setCurrentQty", value);
      },
    },

    price: {
      get() {
        return this.isPrice ? this.$store.state.orderWindow.price : 0;
      },
      set(value) {
        this.$store.commit("orderWindow/setCurrentPrice", value);
      },
    },

    triggerPrice: {
      get() {
        return this.isTrgPrice ? this.$store.state.orderWindow.triggerPrice : 0;
      },
      set(value) {
        this.$store.commit("orderWindow/setCurrentTrgPrice", value);
      },
    },

    disclosedQuantity: {
      get() {
        return this.isDisclosedQty
          ? this.$store.state.orderWindow.disclosedQuantity
          : 0;
      },
      set(value) {
        this.$store.commit("orderWindow/setDisclosedQty", value);
      },
    },
    nseBseRadioSwitch: {
      get() {
        return this.currentExchange;
      },
      set(value) {
        return value;
      },
    },

    buySellRadio: {
      get() {
        return this.orderType == "buy" ? true : false;
      },
      set(value) {
        return value;
      },
    },

    priceType: {
      get() {
        return this.getPriceType;
      },
      set(value) {
        this.$store.commit("orderWindow/setPriceType", value);
      },
    },

    prodType: {
      get() {
        return this.getProdType;
      },
      set(value) {
        this.$store.commit("orderWindow/setProdType", value);
      },
    },

    orderTab: {
      get() {
        return this.getOrderTab;
      },
      set(value) {
        this.$store.commit("orderWindow/setOrderTab", value);
      },
    },

    validityType: {
      get() {
        return this.getValidityType;
      },
      set(value) {
        this.$store.commit("orderWindow/setValidityType", value);
      },
    },

    showValidityItems: {
      get() {
        return this.validityItems;
      },
      set(value) {
        this.$store.commit("orderWindow/setValidityItems", value);
      },
    },
    nudgeDialog: {
      get() {
        return this.$store.state.orderWindow.nudgeDialog;
      },
      set(value) {
        this.$store.commit("orderWindow/setNudgeDialog", value);
      },
    },
  },

  methods: {
    // change tab hide show function
    changeOrderTab() {
      if (this.orderTypeList[this.orderTab] == "Cover") {
        this.showproductItems = this.coverOrBracketProductItems;
        this.showPriceItems = this.coverPriceItems;
        // this.showValidityItems = this.coverOrBracketValidityItems;
        this.$store.commit(
          "orderWindow/setValidityItems",
          this.coverOrBracketValidityItems
        );
      } else if (this.orderTypeList[this.orderTab] == "Bracket") {
        this.showproductItems = this.coverOrBracketProductItems;
        this.showPriceItems = this.bracketPriceItems;
        // this.showValidityItems = this.coverOrBracketValidityItems;
        this.$store.commit(
          "orderWindow/setValidityItems",
          this.coverOrBracketValidityItems
        );
      } else {
        this.showPriceItems = this.isNfo
          ? this.$store.state.orderWindow.allPriceItems
          : this.$store.state.orderWindow.postionPriceItems;
        this.showproductItems =
          this.currentExchange == "NSE" || this.currentExchange == "BSE"
            ? this.$store.state.orderWindow.eqProductItems
            : this.$store.state.orderWindow.allProductItems;
        // this.showValidityItems = this.validityItems;
        this.$store.commit("orderWindow/setValidityItems", ["DAY", "IOC"]);
      }
      this.$store.commit(
        "orderWindow/changeOrderType",
        this.getOrderTabValue()
      );
    },

    // find select order type like regular Or amo
    selectedIndex(selectedOrder) {
      this.currentOrderTab = selectedOrder.toLowerCase();
      this.$store.commit(
        "orderWindow/changeOrderType",
        this.getOrderTabValue()
      );
    },

    //Toggle orderWindow
    async toggleOrderWindow() {
      var order = this.orderType == "buy" ? "sell" : "buy";
      await this.$store.commit("orderWindow/setOrderType", order);
      await this.$store.commit("orderWindow/valiDateQty", "");
      if (this.quantity && !this.marginLoader) {
        await this.$store.dispatch("orderWindow/getMarginAvailable", "Toggle");
      }
    },

    //call orderWindow
    async hideOrderWindow() {
      !this.modifyOrder
        ? this.$store.commit("orderWindow/setOrderWindow", false)
        : this.$store.commit("orderWindow/setOrderWindowModify", false);
      this.$store.commit("orderWindow/setOrderWindowStatesReset");
    },

    // order place
    // async placeOrder () {
    //   this.isFormSubmit = true;
    //   let json = {
    //     qty: this.quantity,
    //     complexity: this.getOrderTabValue(this.ordertab),
    //     pcode: this.prodType.toLowerCase(),
    //     priceType: this.priceType,
    //     price: this.price,
    //     trgPrice: this.triggerPrice,
    //     stopLoss: this.stopLossPrice,
    //     trailStopLoss: this.trailStopLossPrice,
    //     discQty: this.disclosedQuantity,
    //     validity: this.validityType,
    //   };
    //   var qtyInputBox = document.querySelector("#qty");
    //   var priceInputBox = document.querySelector("#price");
    //   var trgPriceInputBox = document.querySelector("#triggerPrice");
    //   var targetPriceInputBox = document.querySelector("#triggerPrice");
    //   var stopLossPriceInputBox = document.querySelector("#stopLossPrice");
    //   var trailStopLossPriceInputBox = document.querySelector("#trailStopLossPrice");
    //   var discQtyInputBox = document.querySelector("#discQty");
    //   this.quantityValidation();
    //   this.priceValidation();
    //   this.triggerPriceValiation();
    //   if (this.orderTypeList[this.orderTab] === 'Bracket') {
    //     this.targetPriceValidation();
    //     this.stopLossPriceValidation();
    //     // this.trailPriceValidation();
    //   }
    //   if (this.showMore) {
    //     this.discQtyValidation();
    //   }
    //   // && trailStopLossPriceInputBox.checkValidity()
    //   if (this.orderTypeList[this.orderTab] == 'Bracket' ? targetPriceInputBox.checkValidity() && stopLossPriceInputBox.checkValidity() : true && qtyInputBox.checkValidity() && this.isPrice ? priceInputBox.checkValidity() : true && this.isTrgPrice ? trgPriceInputBox.checkValidity() : true &&
    //      this.showMore ? discQtyInputBox.checkValidity() : true) {
    //       this.requestData = json
    //         this.nudgeMessage.length == 0 ? this.callService() :  this.nudgeDialog = !this.nudgeDialog
    //   }
    //   this.isFormSubmit = false;
    // },

    async placeOrder() {
      this.isFormSubmit = true;
      let json = {
        qty: this.quantity,
        complexity: this.getOrderTabValue(this.ordertab),
        pcode: this.prodType.toLowerCase(),
        priceType: this.priceType,
        price: this.price,
        trgPrice: this.triggerPrice,
        stopLoss: this.stopLossPrice,
        trailStopLoss: this.trailStopLossPrice,
        discQty: this.disclosedQuantity,
        validity: this.validityType,
      };

      if (
        this.quantityValidation() != "" ||
        (this.isPrice && this.priceValidation() != "") ||
        (this.isTrgPrice && this.triggerPriceValiation() != "")
      )
        return;

      if (this.showMore) {
        if (this.discQtyValidation != "") return;
      }

      if (this.orderTypeList[this.orderTab] == "Bracket") {
        if (
          this.targetPriceValidation() != "" ||
          this.stopLossPriceValidation() != ""
        )
          return;
        if (this.quantityValidation() != "") return;
        if (this.isTrgPrice && this.triggerPriceValiation() != "") return;
        if (this.showMore && this.discQtyValidation() != "") return;
      }

      this.requestData = json;
      this.nudgeMessage.length == 0
        ? this.callService()
        : (this.nudgeDialog = !this.nudgeDialog);
      this.isFormSubmit = false;
    },

    async callService() {
      this.nudgeDialog = false;
      this.hideOrderWindow();
      this.modifyOrder
        ? await this.$store.dispatch(
            "orderWindow/modifyOrder",
            this.requestData
          )
        : await this.$store.dispatch(
            "orderWindow/placeOrder",
            this.requestData
          );
    },
    checkQuantity(event) {
      if (event.keyCode != 13) {
        this.showError = true;
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    decimalAllowed(event) {
      if (event.keyCode != 13) {
        this.showError = true;
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9.]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    // quantity validation
    quantityValidation() {
      var qtyVal = parseFloat(this.quantity);
      var qtyLot = this.floatSafeRemainder(qtyVal, this.minlot);
      let err_msg = "";
      if (this.quantity?.toString() == "") {
        err_msg = "Quantity cannot not be empty";
      } else if (qtyVal == 0) {
        err_msg = "Quantity cannot be zero";
      } else if (qtyLot != 0 && this.minlot > 0) {
        err_msg =
          "Quantity should be multiple of lot size. (" + this.minlot + ")";
      } else {
        // if(!this.marginLoader){
        //   this.$store.dispatch("orderWindow/getMarginAvailable", 'quantityValidation');
        // }
        err_msg = "";
      }
      return err_msg;
    },

    // price validation
    priceValidation() {
      var priceVal = parseFloat(this.price);
      var lowerRangeVal = parseFloat(this.lowerRange);
      var upperRangeVal = parseFloat(this.upperRange);
      var checkDprVal = this.checkDpr(priceVal, upperRangeVal, lowerRangeVal);
      var priceTick = this.floatSafeRemainder(priceVal, this.tickPrice);
      let err_msg = "";
      if (this.price?.toString() == "") {
        err_msg = "Price cannot be Empty";
      } else if (priceVal == 0) {
        err_msg = `Price cannot be zero`;
      } else if (priceTick != 0) {
        err_msg = `Price Should be multiple of Tick size ${this.tickPrice}`;
      } else if (
        !checkDprVal &&
        this.ltp != 0 &&
        Number(this.ltp) >= lowerRangeVal &&
        Number(this.ltp) <= upperRangeVal &&
        this.priceType != "market" &&
        this.priceType != "sl-m"
      ) {
        err_msg = `Price Should be within the DPR range`;
      } else {
        err_msg = ``;
      }
      return err_msg;
    },

    // stoploss price validation
    triggerPriceValiation() {
      var trigPriceVal = parseFloat(this.triggerPrice);
      var trigTick = this.floatSafeRemainder(trigPriceVal, this.tickPrice);
      let err_msg = "";
      if (this.triggerPrice?.toString() == "") {
        err_msg = `Trigger Price cannot be Empty`;
      } else if (trigPriceVal == 0) {
        err_msg = `Trigger Price cannot be zero`;
      } else if (trigTick != 0) {
        err_msg = `Trigger Price Should be multiple of Ticks ${this.tickPrice}`;
      } else {
        err_msg = ``;
      }
      return err_msg;
    },

    //targetPrice based on Exchange
    targetPriceValidation() {
      var value = parseFloat(this.targetPrice);
      var targetTick = this.floatSafeRemainder(value, this.tickPrice);
      let err_msg = "";
      if (this.targetPrice?.toString() == "") {
        err_msg = `Target Price Cannot be Empty`;
      } else if (value == 0) {
        err_msg = `Target Price should be greater than 0`;
      } else if (targetTick != 0) {
        err_msg = `Target Price should be multiple of " + ${this.tickPrice}`;
      } else {
        err_msg = "";
      }
      return err_msg;
    },

    //stopLossPrice based on Exchange
    stopLossPriceValidation() {
      var value = parseFloat(this.stopLossPrice);
      var slTick = this.floatSafeRemainder(value, this.tickPrice);
      let err_msg = "";
      if (this.stopLossPrice?.toString() == "") {
        err_msg = `Stoploss Price Cannot be Empty`;
      } else if (value <= 0) {
        err_msg = `Stoploss Price Cannot be less then 0`;
      } else if (slTick != 0 && slTick != "0") {
        err_msg = `Stoploss Price Should be multiple of " + ${this.tickPrice}`;
      } else {
        err_msg = ``;
      }
      return err_msg;
    },

    //trailPrice based on Exchange
    trailPriceValidation() {
      var value = parseFloat(this.trailStopLossPrice);
      var trailTick = this.floatSafeRemainder(
        this.trailStopLossPrice,
        this.tickPrice
      );
      var targetVal = parseFloat(this.targetPrice);
      let err_msg = "";
      if (this.trailStopLossPrice?.toString() == "") {
        err_msg = "TrailStoploss Price Cannot be Empty";
      } else if (trailTick != 0) {
        err_msg = `TrailStoploss Should be multiple of " + (${this.tickPrice})`;
      } else if (
        this.priceType == "L" &&
        value < this.tickPrice &&
        value != 0
      ) {
        err_msg = `Trailing Stoploss should be minimum of TickPrice`;
      } else if (targetVal < value && value != 0) {
        err_msg = `TrailStoploss Should be Less than " + (${targetVal})`;
      } else {
        err_msg = "";
      }
      return err_msg;
    },

    //Disclosed qty based on Exchange
    discQtyValidation() {
      let dicPercentage;
      var disclosedQty = parseFloat(this.disclosedQuantity);
      var qty = parseFloat(this.currentQty);
      if (this.currentExchange == "MCX") {
        dicPercentage = 25 / this.Qty;
      } else {
        dicPercentage = 10 / this.Qty;
      }
      var disCheck = parseFloat(disclosedQty / qty);
      this.discQtytik = Math.ceil(disCheck);
      let err_msg = "";
      /** Disclosed quantity */
      if (this.currentExchange != "NFO" && this.currentExchange != "BFO") {
        if (this.disclosedQuantity?.toString() == "") {
          err_msg = `Disc.Qty should not be empty`;
        } else if (disclosedQty < this.discQtytik && disclosedQty != 0) {
          if (this.currentExchange == "MCX") {
            err_msg = `Disc.Qty should be either 0 or 25% to 100% of quantity`;
          } else if (this.currentExchange != "MCX") {
            err_msg = `Disc.Qty should be either 0 or 10% to 100% of quantity`;
          } else {
            err_msg = ``;
          }
        } else if (disclosedQty > qty) {
          err_msg = `Disc.Qty cannot be greater than Qty`;
        } else {
          err_msg = ``;
        }
      }
      return err_msg;
    },

    //Float Safe Remainder of last 2 digits or 4 digits
    floatSafeRemainder(val, step) {
      val = val?.toString();
      step = step?.toString();
      var valDecCount = val?.includes(".")
        ? val.split(".")[1].length != null
          ? val.split(".")[1].length
          : 0
        : val.length;
      var stepDecCount = step?.includes(".")
        ? step.split(".")[1].length != null
          ? step.split(".")[1].length
          : 0
        : step?.length;
      var decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      var valInt = parseFloat(
        parseFloat(val)?.toFixed(decCount)?.toString()?.replaceAll(".", "")
      );
      var stepInt = parseFloat(
        parseFloat(step)?.toFixed(decCount)?.toString()?.replaceAll(".", "")
      );
      return (valInt % stepInt) / Math.pow(10, decCount);
    },

    getOrderTabValue() {
      return this.orderTypeList[this.orderTab] == "Regular"
        ? "regular"
        : this.orderTypeList[this.orderTab] == "Cover"
        ? "cover"
        : this.orderTypeList[this.orderTab] == "Bracket"
        ? "bracket"
        : this.orderTypeList[this.orderTab] == "AMO"
        ? "amo"
        : "regular";
    },

    // capitalize first letter
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async nseBseSwitch(exch) {
      await this.$store.commit("orderWindow/makeActive", {
        exch: exch,
        stage: "switch",
      });
      this.priceValidation();
    },

    checkDpr(price, uc, lc) {
      return uc !== 0 && price >= lc && price <= uc;
    },

    getViewLotSize() {
      let ls = "";
      let cdata = this.priceRangeDetails[this.selectedItem];
      if (cdata?.exch == "MCX" && cdata?.infoPhysicalQty) {
        ls = cdata.infoPhysicalQty;
      } else {
        ls = cdata?.lot_size;
      }
      return ls;
    },

    getViewTickSize() {
      let ts = "";
      let cdata = this.priceRangeDetails[this.selectedItem];
      if (cdata?.tick_size) {
        ts = cdata.tick_size;
      }
      return ts;
    },

    getNudgeMessage(){
      let messgae = []

      if(this.nudgeMessage && this.nudgeMessage.length > 0){
        this.nudgeMessage.filter((el)=>{
          messgae.push(el.msg)
        })
      }
      return messgae.toString()
    }
  },

  watch: {
    priceType: async function (val) {
      this.$store.commit(
        "orderWindow/changeOrderType",
        this.getOrderTabValue()
      );
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false &&
        this.repeatOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue(this.orderType),
            orderType: this.priceType,
          })
        );
      }
      if (val == "L") {
        this.price ? this.priceValidation() : "";
      }
      if (val == "SL") {
        this.price ? this.priceValidation() : "";
        this.triggerPrice ? this.triggerPriceValiation() : "";
      }
      if (val == "SL-M") {
        this.triggerPrice ? this.triggerPriceValiation() : "";
      }
      if (val && !this.marginLoader) {
        await this.$store.dispatch("orderWindow/getMarginAvailable", val);
      }
    },

    prodType: async function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false &&
        this.repeatOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue(this.orderTab),
            orderType: this.priceType,
          })
        );
        if (val && !this.marginLoader) {
          await this.$store.dispatch("orderWindow/getMarginAvailable", val);
        }
      }
    },

    orderTab: async function (val) {
      if (!isNaN(val) && !this.marginLoader) {
        await this.$store.dispatch("orderWindow/getMarginAvailable", val);
      }
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false &&
        this.repeatOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue(this.orderTab),
            orderType: this.priceType,
          })
        );
      }
    },

    validityType: function (val) {
      if (
        (this.currentPage == "mkWatch" ||
          this.currentPage == "orders" ||
          this.currentPage == "analytics") &&
        this.modifyOrder == false &&
        this.repeatOrder == false
      ) {
        localStorage.setItem(
          "orderPreference",
          JSON.stringify({
            product: this.prodType,
            validity: this.validityType,
            variety: this.getOrderTabValue(this.orderTab),
            orderType: this.priceType,
          })
        );
      }
    },
  },

  created() {
    if (
      localStorage.getItem("orderPreference") == null ||
      localStorage.getItem("orderPreference") == undefined
    ) {
      localStorage.setItem(
        "orderPreference",
        JSON.stringify({
          product: this.prodType,
          validity: this.validityType,
          variety: this.getOrderTabValue(this.orderTab),
          orderType: this.priceType,
        })
      );
    }
  },
};
